//------------------------\\
//--- Fonctions utiles ---\\
//------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions utilitaires qu'on a souvent besoin et qui ne changent pas
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import { OBSERVER } from '../main'
//-----------------------------------------------------------------------------------------------------------------

// Configurer un cookie
export function setCookie(name, value, exdays='') {
  let date = new Date()
  date.setTime(date.getTime() + (exdays*24*60*60*1000))
  let expires = 'expires=' + date.toUTCString()
  document.cookie = name + '=' + value + ';' + expires + ';path=/'
}


// Recevoir un cookie
export function getCookie(cookie) {
  let name = cookie + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  let i
  for(i=0; i<ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0)
      return c.substring(name.length, c.length)
  }
  return ''
}


// Retourne si l'utilisateur est sur ie
export function isIE() {
  let isIE = false

  if (window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./))
    isIE = true

  return isIE
}


// Retourne si l'utilisateur est sur mobile ou pas
export function isMobile() {
  let isMobile = false

  if((/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    isMobile = true

  return isMobile
}


// Empêche une transition si la destination est la page actuelle
export function noSamePageDestination() {
  var links = document.querySelectorAll('a[href]')
  var cbk = function(e) {
    if (e.currentTarget.href === window.location.href) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener('click', cbk)
  }
}


// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')) styleSheet.deleteRule(ri)
        }
      }
    } catch (ex) { console.error(ex) }
  }
}


// Permet de donner la hauteur exact en mobile de 100vh
export function hundredVH() {
  OBSERVER.add('hundredVH', 'resize', setHeight)
  OBSERVER.add('hundredVH', 'DOMContentLoaded', setHeight)
  OBSERVER.on('hundredVH')

  function setHeight() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
}


// ANCHOR isVisible
// NOTE Permet de véréfier si un élément est visible à l'écran
export function isVisible(e, threshold, mode) {
  threshold = threshold || 0
  mode = mode || 'visible'

  let element = document.querySelector(e)
  let rect = element.getBoundingClientRect()
  let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
  let above = rect.bottom - threshold < 0
  let below = rect.top - viewHeight + threshold >= 0

  return mode === 'above' ? above : (mode === 'below' ? below : !above && !below)
}
