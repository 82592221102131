//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { OBSERVER } from './../main.js'
//-----------------------------------------------------------------------------------------------------------------

// Création du slick de la bannière d'accueil
export function slickBanner() {
  $('#slickBannerImages').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
    slickDots(slick, '#slickBannerNav .slickDots ul', '#slickBannerNav .slickDots ul li', 'height')
  })

  $('#slickBannerImages').slick({
    dots: true,
    arrows: false,
    appendDots: '#slickBannerNav .slickDots',
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    asNavFor: '#slickBannerTexts'
  })
  $('#slickBannerTexts').slick({
    dots: false,
    arrows: false,
    speed: 500,
    fade: true,
    pauseOnHover: false,
    asNavFor: '#slickBannerImages'
  })

  $('#slickBannerNav .slideNumber').on('click', () => { $('#slickBannerImages').slick('next') })

  slickSlideCount('#slickBannerImages', '#slickBannerNav .slideNumber .current', '#slickBannerNav .slideNumber .total')
}


// Création du slick des actualités
export function slickNews() {
  $('#slickNews').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
    slickDots(slick, '#slickNewsNav .slickDots ul', '#slickNewsNav .slickDots ul li', 'width')
  })

  $('#slickNews').slick({
    dots: true,
    arrows: false,
    appendDots: '#slickNewsNav .slickDots',
    infinite: true,
    autoplay: false,
    draggable: true,
    speed: 700,
    variableWidth: true,
    swipeToSlide: true
  })


  $('#slickNewsNav .slideNumber').on('click', () => { $('#slickNews').slick('next') })

  slickSlideCount('#slickNews', '#slickNewsNav .slideNumber .current', '#slickNewsNav .slideNumber .total')
}


// Création du slick des éléments en vedettes
export function slickFeatured() {
  $('#slickFeaturedImages').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
    slickDots(slick, '#slickFeaturedNav .slickDots ul', '#slickFeaturedNav .slickDots ul li', 'width')
  })

  $('#slickFeaturedImages').slick({
    dots: true,
    arrows: false,
    appendDots: '#slickFeaturedNav .slickDots',
    speed: 500,
    fade: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    asNavFor: '#slickFeaturedTexts'
  })

  $('#slickFeaturedTexts').slick({
    dots: false,
    arrows: false,
    speed: 500,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    asNavFor: '#slickFeaturedImages'
  })

  fixSlickAnim('#slickFeaturedTexts')

  $('#slickFeaturedNav .slideNumber').on('click', () => { $('#slickFeaturedImages').slick('next') })

  slickSlideCount('#slickFeaturedImages', '#slickFeaturedNav .slideNumber .current', '#slickFeaturedNav .slideNumber .total')
}


// Création du slick des alertes
export function slickAlerts() {
  if (document.querySelectorAll('#slickAlerts').length != 0) {
    $('#slickAlerts').slick({
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      draggable: true,
      speed: 500,
      autoplaySpeed: 5000,
      slidesToShow: 1,
    })

    let total = $('#slickAlerts .slick-slide:not(.slick-cloned)').length
    let displayedTotal = total < 10 ? + total : total
    $('.l_alerts .slickNav .total').html(displayedTotal)

    $('#slickAlerts').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      let slidePosition = nextSlide + 2 < 10 ? + (nextSlide + 1) : (nextSlide + 1)
      $('.alerts .slickNav .current').html(slidePosition)
    })
  }
  slickSlideCount('#slickAlerts', '.l_alerts .slickNav .current', '.l_alerts .slickNav .total')
}


// Fonction permettant de corriger l'animation d'une slide à une autre en mode infinite
function fixSlickAnim(slider) {
  $(slider).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let direction, slideCountZeroBased = slick.slideCount - 1

    if (nextSlide == currentSlide)
      direction = 'same'
    else if (Math.abs(nextSlide - currentSlide) == 1)
      direction = (nextSlide - currentSlide > 0) ? 'right' : 'left'
    else
      direction = (nextSlide - currentSlide > 0) ? 'left' : 'right'

    if (direction == 'right')
      $('.slick-cloned[data-slick-index="' + (nextSlide + slideCountZeroBased + 1) + '"]', $(slider)).addClass('slickAnimate')

    if (direction == 'left')
      $('.slick-cloned[data-slick-index="' + (nextSlide - slideCountZeroBased - 1) + '"]', $(slider)).addClass('slickAnimate')
  })

  $(slider).on('afterChange', () => {
    $('.slickAnimate', $(slider)).removeClass('slickAnimate')
    $('.slickAnimate', $(slider)).removeClass('slickAnimate')
  })
}


// Fonction permettant de donner une hauteur ou largeur dynamique aux points
function slickDots(slick, dotsContainer, dots, property) {
  let dimension, doc = document

  OBSERVER.add('slickDots', 'resize', onResize, 'window')
  OBSERVER.on('slickDots')

  onResize()

  function onResize() {
    if(doc.querySelector(dotsContainer) !== null){
      dimension = doc.querySelector(dotsContainer).getBoundingClientRect()[property]
      $(dots).css(property, dimension / slick.slideCount + 'px')
    }
  }
}


// Fonction permettant d'afficher la slide active et le nombre total de slide
function slickSlideCount(slickElement, currentElement, totalElement)  {
  $(slickElement).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let slidePosition = nextSlide + 2 < 10 ? '0' + (nextSlide + 1) : (nextSlide + 1)
    $(currentElement).html(slidePosition)
  })

  let total = $(slickElement + ' .slick-slide:not(.slick-cloned)').length
  let displayedTotal = total < 10 ? '0' + total : total
  $(totalElement).html(displayedTotal)
}


// Fonction permettant de détruire un carousel Slick
export function destroySlick(delay = 490) {
  setTimeout(() => {
    $('.slick-initialized').slick('unslick')
  }, delay)
}
