//---------------------------\\
//--- Fonctions générales ---\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions générales qu'on ne peut pas vraiment catégoriser
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { OBSERVER, SCROLLER } from './../main.js'
import { isVisible } from './helper.js'
//-----------------------------------------------------------------------------------------------------------------

// Fonction permettant de défiller d'un block à un autre à l'aide d'un clique
export function scrollToBlock(object) {
  object.destination === undefined ? object.destination = 0 : object.destination
  object.duration    === undefined ? object.duration = 600  : object.duration
  object.offset      === undefined ? object.offset = 0      : object.offset

  OBSERVER.add('scrollToBlock', 'click', onClick, object.buttons)
  OBSERVER.on('scrollToBlock')

  function onClick() {
    let destination = (object.destination === 0 ? object.destination : document.querySelector(object.destination).getBoundingClientRect().top)
    if($(window).width() <= 1024) object.offset = 0
    $('html, body').animate({ scrollTop: destination + object.offset }, object.duration, 'easeInOutExpo')
  }
}


// Fonction initialisant les tiroirs
export function drawers(drawers) {
  $(drawers + ' > li .clickable').on('click', function() {
    let ouvert = $(this).parent().hasClass('open')
    $(drawers + ' li.open .drawer').animate({ height: 'hide' }, { duration: 500, specialEasing: { height: 'easeInOutCubic' }})
    $(drawers + ' li.open').removeClass('open')
    if (!ouvert) {
      let chosen = this
      setTimeout(() => { $(chosen).parent().addClass('open') }, 0)
      $('.drawer', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 500, specialEasing: { height: 'easeInOutCubic' }, complete: function() {
        $('html, body').animate({ scrollTop: $(chosen).offset().top - $('header').height() }, 500, 'easeInOutCubic')
      }})
    }
    return false
  })
}


// Fonction permettant de calculer une hauteur de 100vh en soustrayant la hauteur d'éléments donnés
export function heightFormula(master, substractions) {
  let i, doc = document
  let substractionsNumber = 0
  master = doc.querySelector(master)
  substractions = doc.querySelectorAll(substractions)
  let substractionsLength = substractions.length

  if (master === null) return

  OBSERVER.add('heightFormula', 'resize', calculateHeight, window)
  OBSERVER.add('heightFormula', 'closeAlert', calculateHeightWithoutAlert)
  OBSERVER.on('heightFormula')

  calculateHeight()

  function calculateHeight() {
    let vh = window.innerHeight

    for(i=0; i<substractionsLength; i++) {
      if (substractions[i] !== undefined)
        substractionsNumber += substractions[i].clientHeight
    }
    master.style.height = `calc(${vh}px - ${substractionsNumber}px)`
    substractionsNumber = 0
  }
  
  function calculateHeightWithoutAlert(e) {
    let vh = window.innerHeight

    for(i=0; i<substractionsLength; i++) {
      if (e.detail != substractions[i] && substractions[i] !== undefined)
        substractionsNumber += substractions[i].clientHeight
    }
    master.style.height = `calc(${vh}px - ${substractionsNumber}px)`
    substractionsNumber = 0
  }
}



// Détermine la grosseur du h1 en fonction du nombre de caractères
export function fastLinksBtn() {
  let fastLinksBtn = document.getElementById('btnFastLinks')

  if (fastLinksBtn !== null) {
    let body = document.querySelector('html, body')

    OBSERVER.add('fastLinksBtn', 'scroll', onScroll, 'document')
    OBSERVER.on('fastLinksBtn')

    onScroll()

    function onScroll() {
      if (body.scrollTop > 1)
        fastLinksBtn.classList.add('noText')
      else
        fastLinksBtn.classList.remove('noText')

      if (isVisible('footer'))
        fastLinksBtn.classList.add('isHidden')
      else
        fastLinksBtn.classList.remove('isHidden')
    }
  }
}


// Permet de fermer les alertes desktop et mobile
export function alerts() {
  //let html = document.querySelector('html')

  OBSERVER.add('alerts', 'click', onClick, '.l_alerts .close')
  OBSERVER.on('alerts')

  window.closeAlert = new CustomEvent('closeAlert', { detail: document.querySelector('.l_alerts') })

  /*if (html.classList.contains('hasAlerts')) {
    setTimeout( ()=> {
      dispatchEvent(window.closeAlert)
      html.classList.add('showAlerts')
    }, 3000)
  }*/

  function onClick() {
    dispatchEvent(window.closeAlert)
    document.querySelector('html').classList.remove('showAlerts')
  }
}


// Permet de faire défiler du texte à l'infini
export function marquee() {
  let speed = 15000
  if ($(window).width() < 768)
    speed = 9000

  $('.l_marquee h2').marquee({
    duration: speed,
    duplicated: true,
    gap: 0
  })
}


// ANCHOR sizeH1
// NOTE Détermine la grosseur du h1 en fonction du nombre de caractères
export function sizeH1() {
  let i
  let h1 = document.querySelectorAll('h1, .h1')
  let h1Length = h1.length

  for(i=0; i<h1Length; i++) {
    if(h1[i].textContent.length > 35)
      h1[i].classList.add('smaller')
  }
}


// ANCHOR dragAndSlide
// NOTE Permet de slider les catégories lorsqu'ils sont à l'horizontale
export function dragAndSlide() {
  const slider = document.querySelector('.dragAndSlide')
  let i, startX, timeout, scrollLeft, isDown = false, preventDefault = false

  let links = document.querySelectorAll('dragAndSlide a')
  let linksLength = links.length

  for (i = 0; i < linksLength; i++)
    links[i].ondragstart = function() { return false }

  function mouseDown(e) {
    isDown = true
    slider.classList.add('active')
    startX = e.pageX - slider.offsetLeft
    scrollLeft = slider.scrollLeft
    timeout = setTimeout(() => {
      preventDefault = true
    }, 300)
  }

  function mouseleave() {
    isDown = false
    slider.classList.remove('active')
  }

  function mouseup() {
    clearTimeout(timeout)
    isDown = false
    slider.classList.remove('active')
  }

  function mousemove(e) {
    if(!isDown) return
    e.preventDefault()
    const x = e.pageX - slider.offsetLeft
    const walk = (x - startX) * 2
    slider.scrollLeft = scrollLeft - walk
  }

  function click(e) {
    if(preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    }
    preventDefault = false
  }

  OBSERVER.add('dragAndSlide', 'mousedown', mouseDown, '.dragAndSlide')
  OBSERVER.add('dragAndSlide', 'mouseleave', mouseleave, '.dragAndSlide')
  OBSERVER.add('dragAndSlide', 'mouseup', mouseup, '.dragAndSlide')
  OBSERVER.add('dragAndSlide', 'mousemove', mousemove, '.dragAndSlide')
  OBSERVER.add('dragAndSlide', 'click', click, '.dragAndSlide a')
  OBSERVER.on('dragAndSlide')
}


// ANCHOR customSelect
// NOTE Permet de créer un select avec un style personnalisé
export function customSelect() {
  let i, j, selects, selectsLength, selectSelected, selectItems, selectItem
  let containers = document.querySelectorAll('.custom-select')
  let containersLength = containers.length

  for (i=0; i<containersLength; i++) {
    selects = containers[i].getElementsByTagName('select')[0]
    selectsLength = selects.length

    selectSelected = document.createElement('div')
    selectSelected.setAttribute('class', 'custom-select-selected')
    selectSelected.innerHTML = selects.options[selects.selectedIndex].innerHTML
    containers[i].appendChild(selectSelected)

    selectItems = document.createElement('div')
    selectItems.setAttribute('class', 'custom-select-items')
    for (j=0; j<selectsLength; j++) {
      selectItem = document.createElement('div')
      selectItem.setAttribute('class', 'custom-select-item')
      selectItem.innerHTML = selects.options[j].innerHTML
      selectItems.appendChild(selectItem)
    }

    containers[i].appendChild(selectItems)
  }

  OBSERVER.add('customSelect', 'click', click1, '.custom-select-item')
  OBSERVER.add('customSelect', 'click', click2, '.custom-select-selected')
  OBSERVER.add('customSelect', 'click', click3, '.customContainer')
  OBSERVER.add('customSelect', 'click', closeAllSelect)
  OBSERVER.on('customSelect')

  function closeAllSelect() {
    var x, i, arrNo = []
    x = document.getElementsByClassName('custom-select')

    for (i = 0; i < x.length; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.remove('custom-select-active')
      }
    }
  }

  function click1() {
    let y, i, k, s, h
    s = this.parentNode.parentNode.getElementsByTagName('select')[0]
    h = this.parentNode.previousSibling
    for (i = 0; i < s.length; i++) {
      if (s.options[i].innerHTML == this.innerHTML) {
        s.selectedIndex = i
        h.innerHTML = this.innerHTML
        y = this.parentNode.getElementsByClassName('custom-select-same')
        for (k = 0; k < y.length; k++) {
          y[k].classList.remove('custom-select-same')
        }
        this.setAttribute('class', 'custom-select-item custom-select-same')

        break
      }
    }
    h.click()
  }

  function click2(e) {
    if (!this.parentNode.parentNode.classList.contains('customContainer')) {
      e.stopPropagation()
      this.parentNode.classList.toggle('custom-select-active')
    }
  }

  function click3(e) {
    e.stopPropagation()
    e.preventDefault()
    e.currentTarget.children[1].classList.toggle('custom-select-active')
  }
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}
function fallbackCopyTextToClipboard(text) {
  var pos = $(document).scrollTop()

  var textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}


export function calendar() {
  if (!document.querySelector('.calendar')){ //s'il n'y a pas d'events
    return
  }

  let eventsDatesList = document.querySelector('.calendar').dataset.list
  let currentDate = document.querySelector('.calendar').dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  var datesEnabled = eventsDatesList.split(',')

  $('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    var theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: function (data) { data.then(function(data){ Barba.Pjax.goTo('/evenements/1/' + data['date'])})},
    })
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $('.calendar').datepicker('update', currentDate)
}


export function addSlugToShare() {
  $('.share.rubric').on('click', function(e) {
    window.history.pushState(null, null, '#' + e.currentTarget.id)
    //pour le partage par courriel
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Abbaye d’Oka vélo&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

  //clean l'url quand on ferme l'overlay de share
  $('#overlay, .closeOverlay').on('click', function() {
    window.history.replaceState(null, null, ' ')
    //remettre le partage de la page (sans #)
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Abbaye d’Oka vélo&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })
}

export function manageRubriqueHash() {
  //Pour ouvrir une rubrique dans la même page
  window.onhashchange = function () {
    var id = location.hash.split('#').pop()
    var hash = '#rubrique' + id
    if ($(hash).length) {
      hashDrawer(hash)
    }
  }

  //Pour ouvrir une rubrique dans une autre page
  $('document').ready(function () {

    if (location.hash.length) {
      var id = location.hash.split('#').pop()

      if(id == 'infolettre'){
        $('html, body').animate({ scrollTop: ('#'+id != '' ? $('#'+id).offset().top - 100 : 0) }, 600, 'easeInOutExpo')
      }else{
        var hash = 'li#rubrique' + id
        if ($(hash).length) {
          hashDrawer(hash)
        }
      }
    }
  })
}

// Permet de défiler à une ancre
function hashScrolling(destination) {
  // let offset = document.querySelector('.sRubric > div').dataset.scrollfire != 'fire' ? -100 : 0
  if (SCROLLER.getPageOffset() == 0)
    console.log(destination)
    $('html, body').animate({ scrollTop: (destination != '' ? $(destination).offset().top - 100 : 0) }, 600, 'easeInOutExpo')
}

// Permet de gérer un tiroir avec un défilement
function hashDrawer(element) {
  $('.drawers li.open .drawer').animate({ height: 'hide' }, 400)
  $('.drawers li.open').removeClass('open')
  if (!$(element).hasClass('open')) {
    setTimeout(function () { $(element).addClass('open') }, 0)
    $('.drawer', $(element)).stop(true, false).animate({ height: 'show' }, 400, function () { hashScrolling(element) })
    //Enleve l'ancre de l'url une fois que la rubrique est ouverte
    history.replaceState(null, null, ' ')
  }
  return false
}

export function eventsLoaded(data) {

  if (!data['hasMorePages'])
    $('#eventsMoreBtn').hide()
  else {
    $('#eventsMoreBtn').show()
  }
}

export function activeFilter(element) {
  $('.categFilter').removeClass('active')
  $(element).addClass('active')
}

export function addSpanToBtn() {
  $( ".dynamic a.btn" ).each(function() {
    $(this).append('<span></span>')
  })
}

//Permet d'enlever les accents d'une string
export function slugifyProvider(provider) {
  let result
  result = provider.replace('é', 'e')
  result = result.replace('É', 'E')
  result = result.toLowerCase()

  return result
}

export function forceBlankOnExterneAndPdfLinks() {
  var anchors = document.querySelectorAll('a[href]')
  for (var i = 0; i < anchors.length; i++) {

    if (anchors[i].target != '_blank' && anchors[i].href != 'javascript:;')
    {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol)
      {
        anchors[i].setAttribute('target', '_blank')
      }
    }
  }
}
