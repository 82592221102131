//-------------------------------\\
//--- Importation des scripts ---\\
//-------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main.js importe tous les fichiers qu'on a de besoin, ça commence par les node-modules, puis les fichiers
// propres au projet.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation des nodes modules __________
import './imports/jquery.js'
import $ from 'jquery'
import Barba from 'barba.js'
import 'jquery.easing'
import 'bootstrap-datepicker'
import 'slick-carousel'
import 'slick-carousel/slick/slick-theme.scss'
import 'slick-carousel/slick/slick.scss'
import '@fancyapps/fancybox/dist/jquery.fancybox.js'
import 'jquery.marquee'

import 'jquery-validation'
import 'jquery-mask-plugin'

window.Barba = Barba
window.$ = $

// Importation du style
import '../styles/style.scss'


// Importation des scripts __________
import './transition.js'
import './global.js'


// Importation pour les scripts un peu plus bas __________
import { initPageTransition } from './transition.js'
import { Scrollfire } from './classes/Scrollfire.js'
import { Scroller } from './classes/Scroller.js'
import { Observer } from './classes/Observer.js'


// Démarrage des scripts __________
export const OBSERVER = new Observer()
export const SCROLLFIRE = new Scrollfire()
export const SCROLLER = new Scroller()



$(document).ready(function () {
  initPageTransition()
  setTimeout(() => {
    $('html').removeClass('showLanding')
  }, 500)
  $(document).scrollTop(0)

  $('body').keydown(function (e) {
    var keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey && keycode == 13) || (e.metaKey && keycode == 13)) {
      location = '/administration'
      return false
    }
  })
})
