//-------------------------\\
//--- Classes des views ---\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Overlay from './classes/overlay/overlay.js'
import { SCROLLFIRE, OBSERVER } from './main.js'
import { polyfill } from './functions/polyfills.js'
import { removeHoverOnMobile, hundredVH } from './functions/helper.js'
import { overlayMenu, overlayDropdown, overlayShare, overlaySearch, overlayCalendar, overlayFastLinks } from './functions/overlay.js'
import { inputsAndTextareaLabel, textareaHeight, changeLabelUpload, clearInput, formContact, formJob, formSuccess, resetDropzone, formatPhone, dynamicFormsTelMask, formNewsletter } from './functions/forms.js'
import { drawers, customSelect, sizeH1, scrollToBlock, calendar, fastLinksBtn, alerts, heightFormula, marquee, dragAndSlide, addSpanToBtn, slugifyProvider, forceBlankOnExterneAndPdfLinks, manageRubriqueHash } from './functions/functions.js'
import { slickBanner, destroySlick, slickFastLinks, slickAlerts, slickNews, slickFeatured } from './functions/slicks.js'
//-----------------------------------------------------------------------------------------------------------------

window.slugifyProvider = slugifyProvider
window.formSuccess = formSuccess
window.resetDropzone = resetDropzone
window.formatPhone = formatPhone

// Initialisation sur toutes les pages
export class View {
  static init() {
    // hundredVH()
    // polyfill()
    // removeHoverOnMobile()
    SCROLLFIRE.init()
    overlayMenu()
    overlaySearch()
    overlayFastLinks()
    overlayDropdown()
    slickAlerts()
    alerts()
    fastLinksBtn()
    scrollToBlock({ buttons: '#backToTop' })
    inputsAndTextareaLabel()
    changeLabelUpload()
    clearInput()
    addSpanToBtn()
    forceBlankOnExterneAndPdfLinks()
    formNewsletter()
    manageRubriqueHash()
  }

  static leave() {
    setTimeout(() => {
      OBSERVER.allOff()
      OBSERVER.removeAll()
      Overlay.destroyAll()
      SCROLLFIRE.destroy()
    }, 400)
  }
}


// Classe initialisant les scripts présents dans la page feuille de styles
export class StyleSheet extends View {
  static initStyleSheet() {
    this.init()
    drawers('.m_rubricsList')
    customSelect()
    overlayShare()
    sizeH1()
    heightFormula('.l_pageBanner', 'header, .l_pageInfos, .l_alerts')
    slickNews()
    dynamicFormsTelMask()
    if (typeof $('[data-control=multi-file-uploader]').multiFileUploader == 'function') { //Fix de marde, sinon certaines page crash
      $('[data-control=multi-file-uploader]').multiFileUploader()
    }
  }

  static leaveStyleSheet() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {
  static initHome() {
    this.init()
    slickBanner()
    slickNews()
    slickFeatured()
    marquee()
    heightFormula('.l_homeBanner', '.l_alerts')
  }

  static leaveHome() {
    destroySlick()
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page des actualités
export class News extends View {
  static initNews() {
    this.init()
    overlayShare()
    customSelect()
    dragAndSlide()
  }

  static leaveNews() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de détail d'une actualité
export class NewsDetail extends View {
  static initNewsDetail() {
    this.init()
    overlayShare()
    slickNews()
    sizeH1()
  }

  static leaveNewsDetail() {
    this.leave()
    destroySlick()
  }
}


// Classe initialisant les scripts présents dans la page des événements
export class Events extends View {
  static initEvents() {
    this.init()
    overlayShare()
    customSelect()
    overlayCalendar()
  }

  static leaveEvents() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de détail d'un événement
export class EventDetail extends View {
  static initEventDetail() {
    this.init()
    overlayShare()
    sizeH1()
  }

  static leaveEventDetail() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page des emplois
export class Jobs extends View {
  static initJobs() {
    this.init()
    overlayShare()
    customSelect()
  }

  static leaveJobs() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de détail d'un emploi
export class JobDetail extends View {
  static initJobDetail() {
    this.init()
    overlayShare()
    sizeH1()
    formJob()
  }

  static leaveJobDetail() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de recherche
export class Search extends View {
  static initSearch() {
    this.init()
    overlayShare()
  }

  static leaveSearch() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de contact
export class Contact extends View {
  static initContact() {
    this.init()
    drawers('.m_rubricsList')
    formContact()
    heightFormula('.l_pageBanner', 'header, .l_pageInfos, .l_alerts')
    customSelect()
    overlayShare()
  }

  static leaveContact() {
    this.leave()
  }
}

