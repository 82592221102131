//----------------------------\\
//---- Popup et sidepanel ----\\
//----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import Overlay from '../classes/overlay/overlay.min.js'
import { calendar, copyTextToClipboard } from './functions.js'
//-----------------------------------------------------------------------------------------------------------------

// ANCHOR overlayFastLinks
// NOTE Fonction gérant l'overlay des liens rapides
export function overlayFastLinks() {
  let fastLinks = new Overlay({
    name:    'FastLinks',
    events:  { close: true },
    create:  { close: false, background: false },
    click:   { buttons: { toggle: '.btnFastLinks', close: '.closeOverlayFastLinks' }},
    options: { speed: 600 }
  })

  fastLinks.init()
}

// ANCHOR overlayDropdown
// NOTE Fonction gérant l'overlay du dropdown
export function overlayDropdown() {
  let dropdown = new Overlay({
    name: 'Dropdown',
    create: { close: false },
    click: { buttons: { close: '.closeDropdown' }},
    mouseover: {
      delay: 200,
      buttons: { trigger: '.btnDropdown', close: 'header .btnSearch, header .itemLogo, header form' }
    },
    options: { speed: 900, goToSelector: 'html, body' }
  })

  dropdown.init()
}



// Fonction gérant le Search
export function overlaySearch() {
  let search = new Overlay({
    name: 'Search',
    create: { close: false },
    click: { buttons: { toggle: '.btnSearch', switch: '.btnMenu' }},
    mouseover: { buttons: { close: '.btnDropdown' }},
    animations: {
      addTransition: true,
      styles: [{ property: 'height', value: '100%', easing: 'easeOutQuart' }]
    },
    options: {
      speed: 800,
      goToSelector: 'html, body'
    }
  })

  search.init()
}

// Fonction gérant le menu mobile
export function overlayMenu() {
  let menu = new Overlay({
    name: 'Menu',
    create: { close: false },
    click: {
      buttons: {
        close: '.closeOverlayMenu, header .itemLogo a',
        toggle: '.btnMenu',
        switch: '.btnSearch'
      }
    },
    options: {
      speed: 800,
      goToSelector: 'html, body'
    }
  })

  menu.init()

  $('.groundFloor .btnSubLvl1').on('click', function() {
    var ouvert = $(this).parent().hasClass('open')
    $('.groundFloor li.open .subLvl1').animate({ height: 'hide' }, { duration: 600, specialEasing: { height: 'easeOutExpo' }})
    $('.groundFloor li.open').removeClass('open')
    $('.groundFloor').removeClass('oneIsActive')
    if (!ouvert) {
      var chosen = this
      setTimeout(function() {
        $(chosen).parent().addClass('open')
        $('.groundFloor').addClass('oneIsActive')
      }, 0)
      $('.subLvl1', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 600, specialEasing: { height: 'easeOutExpo' }})
    }
    return false
  })
}


// Fonction gérant l'overlay de partage
export function overlayShare() {

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  let share = new Overlay({
    name: 'Share',
    click: {
      buttons: {
        open: '.btnShare',
        close: '.closeOverlay, .p_blackScreen',
      }
    },
    options: {
      speed: 800,
    }
  })
  share.init()

  $('.m_rubricsList .btnShare').on('click', function(e) {
    window.history.pushState(null, null, '#' + e.currentTarget.id)
    //pour le partage par courriel
    $('#emailShareBtn').attr('href', 'mailto:?Subject=St-Thomas&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

  $(document).find('#copyShareBtn').click( function() {
    copyTextToClipboard(window.location.href)
    alert('Un url a été copié sur votre presse-papiers.')
    return false
  })
}


// Fonction gérant l'overlay de partage
export function overlayCalendar() {
  let calendarOverlay = new Overlay({
    name: 'Calendar',
    events: {
      init: true
    },
    click: {
      buttons: {
        toggle: '.btnCalendar',
      }
    },
    options: {
      speed: 600,
      disabledPageScroll: false
    }
  })

  addEventListener('onInitOverlayCalendar', function () {
    calendar()
  })
  calendarOverlay.init()
}
