//----------------\\
//--- Polyfill ---\\
//----------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les polyfill règlant les problèmes de compatibilité avec IE
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import objectFitImages from 'object-fit-images'
import svg4everybody from 'svg4everybody/dist/svg4everybody.legacy.js'
import cssVars from 'css-vars-ponyfill'
import { isIE } from './helper'
//-----------------------------------------------------------------------------------------------------------------


// Apply all polyfill
export function polyfill() {
  ieCustomEvent()
  fixObjectFitIE()
  svg4everybody()
  cssVarsIE()
}


// Polyfill for custom event in IE
function ieCustomEvent() {
  if ( typeof window.CustomEvent === 'function' ) return false

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    let evt = document.createEvent( 'CustomEvent' )
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail )
    return evt
  }

  CustomEvent.prototype = window.Event.prototype

  window.CustomEvent = CustomEvent
}


// Polyfill pour object-fit sur IE
function fixObjectFitIE() {
  let someImages = document.querySelectorAll('picture img')
  objectFitImages(someImages)
}


// ANCHOR cssVarsIE
// NOTE Permet la compatibilité des variables css avec IE
function cssVarsIE() {
  if (isIE()) {
    cssVars({
      onlyLegacy: false,
      preserveStatic: false,
      preserveVars: true,
      updateURLs: false,
    })
  }
}

