//--------------------------------\\
//--- Validation de formulaire ---\\
//--------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve toutes les validations de formulaire, les messages d'erreur et le formatage.
// Pour que tout fonctionne, il faut les node-modules formatter et jquery-validation déjà.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { OBSERVER } from '../main'
//-----------------------------------------------------------------------------------------------------------------


// LES FONCTIONS
// ANCHOR inputsAndTextareaLabel
// NOTE Ajoute une classe en considérant si le input est plein ou vide
export function inputsAndTextareaLabel() {
  let i,
    formElements = document.querySelectorAll('input[type="text"], textarea'),
    formElementsLength = formElements.length

  for (i=0; i<formElementsLength; i++)
    formElements[i].parentNode.dataset[formElements[i].tagName.toLowerCase() + 'value'] = formElements[i].value

  OBSERVER.add('inputsAndTextareaLabel', 'input', setValue, 'input[type="text"], textarea')
  OBSERVER.on('inputsAndTextareaLabel')

  function setValue(e) { e.target.parentNode.dataset[e.target.tagName.toLowerCase() + 'value'] = e.target.value }
}


// Permet de supprimer le contenu d'un input
export function clearInput() {
  let i
  let btn = document.querySelectorAll('.f_search .btnX')
  let btnLength = btn.length

  for (i = 0; i < btnLength; i++) {
    btn[i].addEventListener('click', (e) => {
      let input = e.currentTarget.parentElement.getElementsByTagName('input')[0]
      input.value = ''
      input.focus()
      input.parentElement.dataset.inputvalue = ''
      input.classList.remove('valid')
    })
  }
}


// Permet de changer le label des input files
export function changeLabelUpload() {
  $('input[type=file]').off('change')
  $('input[type=file]').on('change', function() {
    $(this).next('label').find('span').text($(this).val().split(/(\\|\/)/g).pop())
  })
}


export function textareaHeight() {
  OBSERVER.add('scrollToBlock', 'input', onInput, 'textarea')
  OBSERVER.on('scrollToBlock')

  function onInput(e) {
    e.currentTarget.style.height = '5px'
    e.currentTarget.style.height = (e.currentTarget.scrollHeight) + 'px'
  }
}


// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  if ($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf, docx, doc or rtf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf, docx, doc ou rtf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.'
    }
  }
}


// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement: 'div',
    errorPlacement: function (error, element) {
      error.appendTo(element.parent().parent().find('p.errorMessage'))
    },
    submitHandler: function () {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })
  $(submit).click(function () {
    $(form).submit()
  })
}


// Cette fonction sert à tracer les inputs et envoyer les informations du cart au fur et à mesure qu’on les tape.
// elle est couplée à un evenement Input dans le main.js
export function documentOnKeyup(e) {
  // if there is a timeout, clearIt !
  if (document.firedDelayedInput !== false) {
    clearTimeout(document.firedDelayedInput)
    document.firedDelayedInput = false; // prepare var to get the timeout function
  }
  let target = e.target
  // if target
  if (target.matches('input[data-request][data-blk-track-input]')) {
    // recupération du conteneur d’utilisateur
    let userBlock = target.closest(".infoUser")
    // récupération des inputs, handlerOctober et rowId
    let inputList = userBlock.querySelectorAll(".inputField input")
    let octoberHandle = target.dataset.request
    let rowId = target.dataset.rowId
    let timeOut = target.dataset.blkTrackInput // Time to wait the keyboard before send request
    // construction du payload
    let payload = {
      'rowId': rowId
    };
    inputList.forEach((item) => {
      payload[item.name] = item.value
    })

    document.firedDelayedInput = setTimeout(() => {

      setTimeout(function(){ window.UI.ajaxWaiterOn($('a.delete,#payment-btn')) }, 1);

      // send to october !
      $.request(octoberHandle, {
        data: payload, // le payload dans sa face !!!
        loading: $.oc.stripeLoadIndicator,
        complete: () => window.UI.ajaxWaiterOff()
      })
      document.firedDelayedInput = false // reset the timeout tracking
    }, timeOut);
  }
}

export function payMask() {
  $('#f_payment_zip').mask('A0A 0A0')
  $('#f_payment_cardNumber').mask('0000 0000 0000 0000')
  $('#f_payment_expirationMonth').mask('00')
  $('#f_payment_expirationYear').mask('0000')
  $('#f_payment_ccv').mask('000')
}


export function contactMask() {
  //$('#f_contact_phone').mask('000 000-0000')
}


// Validation du formulaire de contact
export function formContact() {
  validationEmail()
  formatPhone('#f_contact_phone')
  var m = getMessages()
  var rules = {
    destinataire:      { required: true },
    nom:               { required: true },
    prenom:            { required: true },
    adresse:           { required: true },
    courriel:          { required: true, email: true },
    telephone:         { required: true, minlength: 12 },
    message:           { required: true },
  }
  var messages = {
    destinataire:      { required: m.required },
    nom:               { required: m.required },
    prenom:            { required: m.required },
    adresse:           { required: m.required },
    courriel:          { required: m.required, email: m.email },
    telephone:         { required: m.required, minlength: m.phone },
    message:           { required: m.required },
  }
  formValidation('#f_contact', '#f_example_submit', rules, messages)
}

// Validation du formulaire de contact
export function formJob() {
  validationEmail()
  formatPhone('#f_job_phone')
  var m = getMessages()
  var rules = {
    firstName:               { required: true },
    lastName:            { required: true },
    email:          { required: true, email: true },
    phone:         { required: true, minlength: 12 },
    cv:         { required: true },
  }
  var messages = {
    firstName:               { required: m.required },
    lastName:               { required: m.required },
    email:          { required: m.required, email: m.email },
    phone:         { required: m.required,minlength: m.phone },
    cv:         { required: m.required },
  }
  formValidation('#f_job', '#f_job_submit', rules, messages)
}

// LES RÈGLES __________
// Règle du email
export function validationEmail() {
  $.validator.addMethod('courriel', function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}

// LES FORMATS __________
// Format du téléphone
export function formatPhone(element) {
  $(element).mask('000 000-0000')
}

// Reset de validation
export function formSuccess( form, data ) {

  if (data.X_OCTOBER_REDIRECT !== undefined) {
    $('body').removeClass('oc-loading')
    $('.stripe-loading-indicator').addClass('loaded')
    $(form)[0].reset()
    resetDropzone(form)
    if ( typeof grecaptcha !== 'undefined' ) grecaptcha.reset()
    $.oc.flashMsg({text: 'Le formulaire est bien envoyé !',class: 'success'})
  }

}

// RESET LES DROPZONE DU FORMULAIRE
export function resetDropzone(formObj) {
  // recherche des dropzones du form
  let dropZoneList = $('.fileField',$(formObj))

  // reset des dropzones du form
  dropZoneList.each(function( index, element ) {
    // pour chaque drop zone trouvé dans le form
    // on cherche son instance de Dropzone
    let dropZoneInstance = getDropzone( element.id )
    dropZoneInstance.removeAllFiles()
  })

}

// Retourne la dropZone en fonction de son nom
export function getDropzone( name ) {
  let selectedDropzone = false
  Dropzone.instances.forEach(function (dropzone) {
    if ( dropzone.element.id == name ) selectedDropzone = dropzone
  })
  return selectedDropzone
}

// Validation du formulaire de contact
export function dynamicFormsTelMask() {
  formatPhone('.form-control.mask-phone')
}

// Validation du formulaire de contact
export function formNewsletter() {
  validationEmail()
  var m = getMessages()
  var rules = {
    email:          { required: true, email: true },
  }
  var messages = {
    email:          { required: m.required, email: m.email },
  }
  formValidation('#f_newsletter', '#f_newsletter_submit', rules, messages)
}